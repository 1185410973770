"use client";

import Image from "next/image";

import thumbnail from "../../public/images/thumbnail.svg";
import { useGlobalStore } from "@/zustand/initStore";

export default function AuthLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const { config } = useGlobalStore();

  return (
    <div className="w-screen h-screen overflow-hidden">
      <div className="flex items-center justify-center w-full h-full">
        <div className="hidden md:flex items-center justify-center flex-col w-[60%] bg-primary h-full px-10 py-8">
          {config && (
            <Image
            // @ts-ignore
              src={config?.logo}
              width={100}
              height={100}
              alt="logo_image_of_login"
              className="self-start"
              priority
            />
          )}
          <Image
            src={thumbnail}
            alt="thumbnail_image_of_login"
            className="flex-1"
            priority
          />
        </div>

        {children}
      </div>
    </div>
  );
}
